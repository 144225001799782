// SectionRefsContext.js
import React, { createContext, useRef } from 'react';

export const SectionRefsContext = createContext();

export const SectionRefsProvider = ({ children }) => {
  const section0Ref = useRef(null);
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);
  const section5Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);

  return (
    <SectionRefsContext.Provider value={{ section0Ref, section1Ref, section2Ref, section3Ref, section4Ref, section5Ref, section6Ref, section7Ref }}>
      {children}
    </SectionRefsContext.Provider>
  );
};
