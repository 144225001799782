import React, { useEffect , useState, useRef, useContext  } from 'react'
import Slider from "react-slick";
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import img_1 from '../../assets/images/fnb/food_1.png';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import { SectionRefsContext } from '../../contex/SectionRefsProvider';
import SliderExperimental from '../../components/SliderExperimental/SliderExperimental';

function OurClient() {
    const { section5Ref } = useContext(SectionRefsContext);
    return (
        <Box ref={section5Ref} sx={{backgroundColor:'#f5f5f5'}} py={5}>
            <Box sx={{backgroundColor:'#f5f5f5'}} py={5}>
                <Container maxWidth="xl">
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        pb={{ xs: 2, sm: 2, md: 5, lg: 5, xl: 5 }}
                    >
                        <Typography variant="h4" sx={{fontWeight:"bold"}}>Client</Typography>
                    </Box>
                    <Box>
                        <SliderExperimental />
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default OurClient;
