import React, { useEffect , useState, useRef, useContext  } from 'react'
import Slider from "react-slick";
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Chip,
    Button,
} from '@mui/material';
import { SectionRefsContext } from '../../contex/SectionRefsProvider';
import Map from '../../components/Map/Map';
import LocationOnIcon from '@mui/icons-material/LocationOn';
// Images
import clinical_laboratory_nolabel from '../../assets/images/services/clinical_laboratory_nolabel.png';
import doctor_specialist_nolabel from '../../assets/images/services/doctor_specialist_nolabel.png';
import home_care_nolabel from '../../assets/images/services/home_care_nolabel.png';
import medical_check_up_nolabel from '../../assets/images/services/medical_check_up_nolabel.png';

const mapData = [
    {
        title:"Klinik Utama Prolepsis Medical Centers",
        address:`Jl. Lamandau I No.21, RT.3/RW.7, Kramat Pela, Kec. Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12130`,
        center:{
            lat: -6.243568196641118,
            lng: 106.794224251305
        },
        link:'https://www.google.com/maps/dir/?api=1&destination=-6.243568196641118,106.794224251305',
    },
    {
        title:"Prolepsis medical centers bekasi",
        address:`Ruko Greenwood Golden City Bekasi Unit Nomor C2, Jl. Perum Villa Indah Permai No.06, Kota Bks, Jawa Barat 17121`,
        center:{
            lat: -6.2028035511058395,
            lng: 107.0233088928916
        },
        link:'https://www.google.com/maps/dir/?api=1&destination=-6.2028035511058395,107.0233088928916',
    },
    {
        title:"Prolepsis medical centers Sidoarjo ",
        address:`Ruko Chiwalk Citra Harmoni Blok OC no. 26-27 Trosobo Kec. Taman Kab. Sidoarjo - Jawa Timur 61257`,
        center:{
            lat: -7.3725635710815896,
            lng: 112.6502215864238
        },
        link:'https://www.google.com/maps/dir/?api=1&destination=-7.3725635710815896,112.6502215864238',
    },
]

function OurLocations() {
    const { section7Ref } = useContext(SectionRefsContext);
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0
    });

    useEffect(() => {
        setCenter(mapData[0].center)
    }, []);

    return (
        <Box ref={section7Ref} sx={{backgroundColor:'#f5f5f5'}} py={5}>
            <Box sx={{backgroundColor:'#f5f5f5'}} py={5}>
                <Container maxWidth="xl">
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        mb={{ xs: 2, sm: 2, md: 15, lg: 15, xl: 15 }}
                    >
                        <Typography variant="h4" sx={{fontWeight:"bold"}}>We are available in 3 city</Typography>
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        flexWrap={'wrap'}
                    >
                        <Box
                            sx={{
                                height:'600px',
                                width:{ xs: '100%', sm: '100%', md: '40%', lg: '40%', xl: '40%' },
                                p:5,
                                overflow:'auto',
                            }}
                        >
                            {
                                mapData.map((item,index) => 
                                    <Box
                                        key={index}
                                        sx={{
                                            borderBottom: (mapData.length - 1) != index ? `2px solid #cccccc` : `0px solid #000`
                                        }}
                                        py={2}
                                    >
                                        <Box
                                            sx={{
                                                cursor:'pointer'
                                            }}
                                            onClick={()=> {setCenter(item.center)}}
                                        >
                                            <Box>
                                                <Typography variant="h5" sx={{fontWeight:"bold"}} align='left'>{item.title}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="subtitle1" align='left'>{item.address}</Typography>
                                            </Box>
                                        </Box>
                                        <Box 
                                            display={"flex"}
                                            justifyContent={'start'}
                                            alignItems={"start"}
                                        >
                                            <Button onClick={() => {window.open(item.link, '_blank');}} startIcon={<LocationOnIcon />} variant="contained">Get Direction</Button>
                                        </Box>
                                    </Box>
                                )
                            }
                        </Box>
                        <Box
                            sx={{
                                height:'600px',
                                width:{ xs: '100%', sm: '100%', md: '60%', lg: '60%', xl: '60%' },
                                p:5
                            }}
                        >
                            <Map center={center} />
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default OurLocations;
