import React, { useEffect , useState, useRef, useContext  } from 'react'
import Slider from "react-slick";
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Chip,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import img_1 from '../../assets/images/fnb/food_1.png';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import ServiceSlider from '../../components/ServiceSlider/ServiceSlider';
import { SectionRefsContext } from '../../contex/SectionRefsProvider';
// Images
import clinical_laboratory_nolabel from '../../assets/images/services/clinical_laboratory_nolabel.png';
import doctor_specialist_nolabel from '../../assets/images/services/doctor_specialist_nolabel.png';
import home_care_nolabel from '../../assets/images/services/home_care_nolabel.png';
import medical_check_up_nolabel from '../../assets/images/services/medical_check_up_nolabel.png';

function OurServices() {
    const { section4Ref } = useContext(SectionRefsContext);
    const mockData = [1,2,3,4,5]
    return (
        <Box ref={section4Ref} sx={{backgroundColor:'#f5f5f5'}} py={5}>
            <Box sx={{backgroundColor:'#f5f5f5'}} py={5}>
                <Container maxWidth="xl">
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        mb={{ xs: 2, sm: 2, md: 15, lg: 15, xl: 15 }}
                    >
                        <Typography variant="h4" sx={{fontWeight:"bold"}}>Our Services</Typography>
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        flexWrap={'wrap'}
                    >
                        <Box
                            sx={{
                                width:{ xs: '100%', sm: '100%', md: '50%', lg: '50%', xl: '50%' },
                                p:5
                            }}
                        >
                            <Box
                                display={"flex"}
                                justifyContent={'center'}
                                alignItems={"center"}
                                mb={5}
                            >
                                <Box
                                    sx={{
                                        backgroundColor:'#66b8dd',
                                        borderRadius:50
                                    }}
                                    p={3}
                                >
                                    <img 
                                        src={clinical_laboratory_nolabel} 
                                        width={'80px'}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant="h4" sx={{fontWeight:"bold"}}>Clinical Laboratory</Typography>
                            </Box>
                            <Box sx={{textAlign:'center'}}>
                                <Typography variant="subtitle1">
                                A comprehensive selection of tests, ranging from standard and specialized check-ups to advanced innovations such as gene-based testing.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width:{ xs: '100%', sm: '100%', md: '50%', lg: '50%', xl: '50%' },
                                p:5
                            }}
                        >
                            <Box
                                display={"flex"}
                                justifyContent={'center'}
                                alignItems={"center"}
                                mb={5}
                            >
                                <Box
                                    sx={{
                                        backgroundColor:'#66b8dd',
                                        borderRadius:50
                                    }}
                                    p={3}
                                >
                                    <img 
                                        src={medical_check_up_nolabel} 
                                        width={'80px'}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant="h4" sx={{fontWeight:"bold"}}>Medical Check-Up</Typography>
                            </Box>
                            <Box sx={{textAlign:'center'}}>
                                <Typography variant="subtitle1">
                                Delivering customized health management programs that prioritize early disease detection and prevention tailored to your specific requirements.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width:{ xs: '100%', sm: '100%', md: '50%', lg: '50%', xl: '50%' },
                                p:5
                            }}
                        >
                            <Box
                                display={"flex"}
                                justifyContent={'center'}
                                alignItems={"center"}
                                mb={5}
                            >
                                <Box
                                    sx={{
                                        backgroundColor:'#66b8dd',
                                        borderRadius:50
                                    }}
                                    p={3}
                                >
                                    <img 
                                        src={doctor_specialist_nolabel} 
                                        width={'80px'}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant="h4" sx={{fontWeight:"bold"}}>Doctor Specialist</Typography>
                            </Box>
                            <Box sx={{textAlign:'center'}}>
                                <Typography variant="subtitle1">
                                Our dedicated specialist doctors are committed to delivering comprehensive care, from diagnosis to treatment, ensuring your health is in expert hands.
                                </Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                width:{ xs: '100%', sm: '100%', md: '50%', lg: '50%', xl: '50%' },
                                p:5
                            }}
                        >
                            <Box
                                display={"flex"}
                                justifyContent={'center'}
                                alignItems={"center"}
                                mb={5}
                            >
                                <Box
                                    sx={{
                                        backgroundColor:'#66b8dd',
                                        borderRadius:50
                                    }}
                                    p={3}
                                >
                                    <img 
                                        src={home_care_nolabel} 
                                        width={'80px'}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant="h4" sx={{fontWeight:"bold"}}>Home Care</Typography>
                            </Box>
                            <Box sx={{textAlign:'center'}}>
                                <Typography variant="subtitle1">
                                Our home care services provide personalized treatment plans designed to meet your specific health needs, ensuring you receive the best care without leaving home.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Container maxWidth="xl">
                    <Box>
                        <ServiceSlider />
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default OurServices;
