import React, { useEffect , useState, useRef, useContext  } from 'react'
import Slider from "react-slick";
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    TextField,
    Button,
} from '@mui/material';
//services//
import EmailService from '../../services/Email.service'
import { SectionRefsContext } from '../../contex/SectionRefsProvider';

function ContactUs() {
    const { section6Ref } = useContext(SectionRefsContext);
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [phone, setPhone] = useState(null);
    const [message, setMessage] = useState(null);
    const [errorList, setErrorList] = useState([]);
    const [sendData, setSendData] = useState([])
    const mockData = [1,2,3,4,5,6,7,8,9,10,11,12]
    const serviceData = [
        {
            type:`text`,
            imageLink:``,
            description:`Personalized pre-counseling and medical exam.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/img-20180306-wa0029-4.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Blood tests, vaccine history and profile completion (i.e. dietary habits, physical activity).`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-c.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Performance analysis of the heart, and cardiopulmonary system.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-a-1.jpg`,
            description:``,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-d.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Standard check-ups and tailored examinations depending on patient’s needs.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/img-20180306-wa0024-1.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Specialized imaging technologies, as necessary.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/002.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Assessment and in-depth explanation of results by our medical experts.`,
        },
    ]

    // useEffect(() => {
    //     console.warn(`check sendData`,sendData);
    // }, [sendData]);

    const handleSubmit = async () => {
        let errorListTemp = []
        if(name == null || name == ''){
            errorListTemp.push({
                type:'name'
            })
        }
        if(email == null || email == ''){
            errorListTemp.push({
                type:'email'
            })
        }
        if(errorListTemp.length==0){
            try{
                let params = {
                    name:name,
                    email:email,
                    phone:phone,
                    message:message,
                }
                await EmailService.sendMessage(params)
                let dataToSend = {
                    name:name,
                    email:email,
                    phone:phone,
                    message:message,
                }
                setSendData([...sendData,dataToSend])
            } catch (error){
                console.warn(error);
                alert(`Something is not right, please contact admin.`)
            }
        }
        setErrorList([...errorListTemp])
    }

    const getErrorMatch = (value) => {
        let val = false
        if(errorList.length>0){
            let errorFind = errorList.filter((x) => x.type == value)
            if(errorFind.length>0){
                val = true
            }
        }
        return val
    }

    const handleGoBack = () => {
        setName('')
        setEmail('')
        setPhone('')
        setMessage('')
        let newData = []
        setErrorList([...newData])
        setSendData([...newData])
    }

    return (
        <Box ref={section6Ref} sx={{backgroundColor:'#f5f5f5'}} py={5}>
            <Box sx={{backgroundColor:'#f5f5f5'}} py={5}>
                <Container maxWidth="xl">
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        pb={5}
                    >
                        <Typography variant="h4" sx={{fontWeight:"bold"}}>Contact Us</Typography>
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                    >
                        <Box
                            sx={{
                                width:{ xs: '90%', sm: '90%', md: '60%', lg: '60%', xl: '60%' },
                                backgroundColor:'#fff',
                            }}
                            py={2}
                            px={4}
                        >
                            <Typography variant="subtitle1" sx={{color:'#000',fontWeight:'bold'}} textAlign={'start'}>
                                Name (required)
                            </Typography>
                            <TextField 
                                variant="outlined" 
                                sx={{width:'100%'}} 
                                value={name}
                                onChange={(value) => setName(value.target.value)}
                                error={getErrorMatch('name')}
                                helperText={getErrorMatch('name') === true ? `Please fill name !` : ``}
                                disabled={sendData.length>0}
                            />
                        </Box>
                        <Box
                            sx={{
                                width:{ xs: '90%', sm: '90%', md: '60%', lg: '60%', xl: '60%' },
                                backgroundColor:'#fff',
                            }}
                            py={2}
                            px={4}
                        >
                            <Typography variant="subtitle1" sx={{color:'#000',fontWeight:'bold'}} textAlign={'start'}>
                                Email (required)
                            </Typography>
                            <TextField 
                                variant="outlined" 
                                sx={{width:'100%'}} 
                                value={email}
                                onChange={(value) => setEmail(value.target.value)}
                                error={getErrorMatch('email')}
                                helperText={getErrorMatch('email') === true ? `Please fill email !` : ``}
                                disabled={sendData.length>0}
                            />
                        </Box>
                        <Box
                            sx={{
                                width:{ xs: '90%', sm: '90%', md: '60%', lg: '60%', xl: '60%' },
                                backgroundColor:'#fff',
                            }}
                            py={2}
                            px={4}
                        >
                            <Typography variant="subtitle1" sx={{color:'#000',fontWeight:'bold'}} textAlign={'start'}>
                                Phone
                            </Typography>
                            <TextField 
                                variant="outlined" 
                                sx={{width:'100%'}} 
                                value={phone}
                                onChange={(value) => setPhone(value.target.value)}
                                disabled={sendData.length>0}
                            />
                        </Box>
                        <Box
                            sx={{
                                width:{ xs: '90%', sm: '90%', md: '60%', lg: '60%', xl: '60%' },
                                backgroundColor:'#fff',
                            }}
                            py={2}
                            px={4}
                        >
                            <Typography variant="subtitle1" sx={{color:'#000',fontWeight:'bold'}} textAlign={'start'}>
                                Message
                            </Typography>
                            <TextField 
                                variant="outlined" 
                                sx={{
                                    width:'100%'
                                }} 
                                multiline
                                rows={4}
                                value={message}
                                onChange={(value) => setMessage(value.target.value)}
                                disabled={sendData.length>0}
                            />
                        </Box>
                        {
                            sendData.length>0?
                            <Box
                                sx={{
                                    width:{ xs: '90%', sm: '90%', md: '60%', lg: '60%', xl: '60%' },
                                    backgroundColor:'#fff',
                                }}
                                py={2}
                                px={4}
                            >
                                <Typography variant="subtitle1" sx={{color:'#000',fontWeight:'bold'}} textAlign={'start'}>
                                    Your message has been sent
                                </Typography>
                                <Box
                                    display={'flex'}
                                    justifyContent={'left'}
                                    alignItems={'center'}
                                >
                                    <Button variant="contained" onClick={()=> handleGoBack()}>Go Back</Button>
                                </Box>
                            </Box>
                            :
                            <Box
                                sx={{
                                    width:{ xs: '90%', sm: '90%', md: '60%', lg: '60%', xl: '60%' },
                                    backgroundColor:'#fff',
                                }}
                                py={2}
                                px={4}
                            >
                                <Button variant="contained" onClick={()=> handleSubmit()}>Send Message</Button>
                            </Box>
                        }
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default ContactUs;
