import React, {useEffect,useState,useContext} from 'react'
import {
    Container,
    Box,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import { SectionRefsContext } from '../../contex/SectionRefsProvider';
// Images
import small_logo from '../../assets/images/small_logo.png';
import logo from '../../assets/images/logo.png';

const NavHeaderComponent = () => {
    const { section0Ref, section1Ref, section2Ref, section3Ref, section4Ref, section5Ref, section6Ref, section7Ref } = useContext(SectionRefsContext);
    const [dreawerIsOpen,setDrawerIsOpen] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(0);
    const Navigate = useNavigate()
    const [nav,setNav] = useState(null)

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
        };
    
        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array ensures the effect runs only once

    const scrollToSection = (sectionRef) => {
        const offsetTop = sectionRef.current.getBoundingClientRect().top + window.scrollY - 150;
        // sectionRef.current.scrollIntoView({ behavior: 'smooth' });
        window.scrollTo(0, offsetTop)
    };
    
    return( 
        <Box>
            <Box display={"flex"} sx={{backgroundColor:'rgba(102, 184, 221, 1)'}}>
                <Container maxWidth="xl">
                    <Box display={"flex"} sx={{height:'50px'}}>
                        <Box 
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            sx={{
                                width:'100%',
                                display: 'flex'
                            }}
                        >
                            <Box 
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={'start'}
                                alignItems={"center"}
                            >
                                <Box p={1} mr={3} sx={{cursor:'pointer'}} onClick={()=>{setNav(null);scrollToSection(section0Ref);}}>
                                    <img 
                                        src={small_logo} 
                                        width={'150px'}
                                        // style={{
                                        //     width:'100%',
                                        //     height:'100%',
                                        //     objectFit:'cover',
                                        // }} 
                                    />
                                </Box>
                            </Box>
                            <Box 
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={'start'}
                                alignItems={"center"}
                            >
                                <Box 
                                    display={"flex"}
                                    flexDirection={"row"}
                                    alignItems={"center"}
                                >
                                    <Box 
                                        p={1} 
                                    >
                                        <Button startIcon={<PhoneInTalkIcon />} variant="contained" onClick={()=>{setNav(null);scrollToSection(section6Ref);}}>Contact Us</Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>
            <Box display={"flex"} sx={{backgroundColor:'rgba(255, 255, 255, 1)'}}>
                <Container maxWidth="xl">
                    <Box display={"flex"} sx={{height:'100px'}}>
                        <Box 
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            sx={{
                                width:'100%',
                                display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' }
                            }}
                        >
                            <Box 
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={'start'}
                                alignItems={"center"}
                            >
                                <Box p={1} mr={3} sx={{cursor:'pointer'}} onClick={()=>{setNav(null);scrollToSection(section0Ref);}}>
                                    <img 
                                        src={logo} 
                                        width={'200px'}
                                        // style={{
                                        //     width:'100%',
                                        //     height:'100%',
                                        //     objectFit:'cover',
                                        // }} 
                                    />
                                </Box>
                            </Box>
                            <Box 
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={'start'}
                                alignItems={"center"}
                            >
                                <Box 
                                    display={"flex"}
                                    flexDirection={"row"}
                                    alignItems={"center"}
                                >
                                    <Box 
                                        p={1} 
                                        sx={{cursor:'pointer',color:nav=="Our Mission"?'#00b7ff':'#000000'}}
                                        onClick={()=>{setNav("Our Mission");scrollToSection(section1Ref);}}
                                    >
                                        <Typography data-translate-nav-header>
                                            About
                                        </Typography>
                                    </Box>
                                    {/* <Box 
                                        p={1} 
                                        sx={{cursor:'pointer',color:nav=="About Us"?'#00b7ff':'#000000'}}
                                        onClick={()=>{setNav("About Us");scrollToSection(section2Ref);}}
                                    >
                                        <Typography data-translate-nav-header>
                                            About Us
                                        </Typography>
                                    </Box>
                                    <Box 
                                        p={1} 
                                        sx={{cursor:'pointer',color:nav=="What We Do"?'#00b7ff':'#000000'}}
                                        onClick={()=>{setNav("What We Do");scrollToSection(section3Ref);}}
                                    >
                                        <Typography data-translate-nav-header>
                                            What We Do
                                        </Typography>
                                    </Box> */}
                                    <Box 
                                        p={1} 
                                        sx={{cursor:'pointer',color:nav=="Our Services"?'#00b7ff':'#000000'}}
                                        onClick={()=>{setNav("Our Services");scrollToSection(section4Ref);}}
                                    >
                                        <Typography data-translate-nav-header>
                                            Services
                                        </Typography>
                                    </Box>
                                    <Box 
                                        p={1} 
                                        sx={{cursor:'pointer',color:nav=="Our Client"?'#00b7ff':'#000000'}}
                                        onClick={()=>{setNav("Our Client");scrollToSection(section5Ref);}}
                                    >
                                        <Typography data-translate-nav-header>
                                            Client
                                        </Typography>
                                    </Box>
                                    <Box 
                                        p={1} 
                                        sx={{cursor:'pointer',color:nav=="Locations"?'#00b7ff':'#000000'}}
                                        onClick={()=>{setNav("Locations");scrollToSection(section7Ref);}}
                                    >
                                        <Typography data-translate-nav-header>
                                            Locations
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box 
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            sx={{
                                width:'100%',
                                display: { xs: 'flex', sm: 'flex', md: 'none', lg: 'none', xl: 'none' }
                            }}
                        >
                            <Box p={1} mr={3} sx={{cursor:'pointer'}} onClick={()=>{setNav(null);scrollToSection(section0Ref);}}>
                                <img 
                                    src={logo} 
                                    width={'200px'}
                                    // style={{
                                    //     width:'100%',
                                    //     height:'100%',
                                    //     objectFit:'cover',
                                    // }} 
                                />
                            </Box>
                            <Box 
                                display={"flex"}
                                flexDirection={"row"}
                                justifyContent={'flex-end'}
                                alignItems={"center"}
                                sx={{
                                    width:'100%',
                                }}
                            >
                                <Box p={1} sx={{cursor:'pointer'}} onClick={()=>{setDrawerIsOpen(!dreawerIsOpen)}}>
                                    <MenuIcon sx={{ color:'#000', fontSize: 40 }} />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Drawer open={dreawerIsOpen} onClose={() => setDrawerIsOpen(!dreawerIsOpen)}>
                    <Box sx={{ width: 250, height:'100%', backgroundColor:'#4f4f4f' }} role="presentation" onClick={() => setDrawerIsOpen(!dreawerIsOpen)}>
                        <List>
                            <ListItem disablePadding>
                                <Typography variant="h6" sx={{color:'#ffffff'}}>
                                    PROLEPSIS
                                </Typography>
                            </ListItem>
                        </List>
                        <Divider />
                        <List>
                            <ListItem disablePadding onClick={()=>{setNav("Our Mission");scrollToSection(section1Ref);}}>
                                <Box 
                                    p={1} 
                                    sx={{cursor:'pointer',color:nav=="Our Mission"?'#00b7ff':'#ffffff'}}
                                >
                                    <Typography data-translate-nav-header>
                                        About
                                    </Typography>
                                </Box>
                            </ListItem>
                            <ListItem disablePadding onClick={()=>{setNav("Our Services");scrollToSection(section4Ref);}}>
                                <Box 
                                    p={1} 
                                    sx={{cursor:'pointer',color:nav=="Our Services"?'#00b7ff':'#ffffff'}}
                                >
                                    <Typography data-translate-nav-header>
                                        Services
                                    </Typography>
                                </Box>
                            </ListItem>
                            <ListItem disablePadding onClick={()=>{setNav("Our Client");scrollToSection(section5Ref);}}>
                                <Box 
                                    p={1} 
                                    sx={{cursor:'pointer',color:nav=="Our Client"?'#00b7ff':'#ffffff'}}
                                >
                                    <Typography data-translate-nav-header>
                                        Client
                                    </Typography>
                                </Box>
                            </ListItem>
                            <ListItem disablePadding onClick={()=>{setNav("Locations");scrollToSection(section7Ref);}}>
                                <Box 
                                    p={1} 
                                    sx={{cursor:'pointer',color:nav=="Locations"?'#00b7ff':'#ffffff'}}
                                >
                                    <Typography data-translate-nav-header>
                                        Locations
                                    </Typography>
                                </Box>
                            </ListItem>
                        </List>
                    </Box>
                </Drawer>
            </Box>
        </Box>
    )
}

export default NavHeaderComponent