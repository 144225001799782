import React, { useEffect , useState, useRef } from 'react'
import { useTranslation } from '../../contex/TranslationContext';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    CircularProgress,
} from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
// Images
import ekg from '../../assets/images/service-slider/ekg.png';
import radiologi from '../../assets/images/service-slider/radiologi.png';
import usg from '../../assets/images/service-slider/usg.png';
import audiometri from '../../assets/images/service-slider/audiometri.png';
import spirometri from '../../assets/images/service-slider/spirometri.png';
import darah from '../../assets/images/service-slider/darah.png';
import laboraturium from '../../assets/images/service-slider/laboraturium.png';
import visus from '../../assets/images/service-slider/visus.png';
import konsultasi_dokter_umum_dan_spesialis from '../../assets/images/service-slider/konsultasi-dokter-umum-dan-spesialis.png';
import perawatan_ibu from '../../assets/images/service-slider/perawatan-ibu.png';
import igd from '../../assets/images/service-slider/igd.png';
import praktek_medis from '../../assets/images/service-slider/praktek-medis.png';
import vaksinasi from '../../assets/images/service-slider/vaksinasi.png';
import pap_smear from '../../assets/images/service-slider/pap-smear.png';
import tht from '../../assets/images/service-slider/tht.png';
import perawatan_rumah from '../../assets/images/service-slider/perawatan-rumah.png';

function SliderExperimental({data}) {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };
  let sliderRefSmall = useRef(null);
  const nextSmall = () => {
    sliderRefSmall.slickNext();
  };
  const previousSmall = () => {
    sliderRefSmall.slickPrev();
  };
  const [activeSlide, setActiveSlide] = useState(0);
  const [settings, setSettings] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    draggable: false,
    swipe: false,  
  });
  const [settingsSmall, setSettingsSmall] = useState({
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setActiveSlide(next);
    },
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    draggable: false,
    swipe: false,  
  });
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'none'}}
        onClick={onClick}
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: 'none' }}
        onClick={onClick}
      />
    );
  }

  return (
    <Box py={5}>
      <Box sx={{backgroundColor:'#f5f5f5'}}>
        <Container maxWidth="xl">
          <Box
            sx={{
              position:'relative'
            }}
            py={5}
          >
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}>
                <>
                    <Slider
                        ref={slider => {
                        sliderRef = slider;
                        }}
                        {...settings}
                    >
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={ekg}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        EKG
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={radiologi}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        RADIOLOGI
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={usg}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        USG
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={audiometri}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        AUDIOMETRI
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={spirometri}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        SPIROMETRI
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={darah}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        DARAH
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={laboraturium}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        LABORATURIUM
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={visus}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        VISUS
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={konsultasi_dokter_umum_dan_spesialis}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        KONSULTASI DOKTER UMUM DAN SPESIALIS
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={perawatan_ibu}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        PERAWATAN IBU
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={igd}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        IGD
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={praktek_medis}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        PRAKTEK MEDIS
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={vaksinasi}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        VAKSINASI
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={pap_smear}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        PAP SMEAR
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={tht}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        THT
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={perawatan_rumah}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        PERAWATAN RUMAH
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    </Slider>
                    {
                        activeSlide !== 0 &&
                        <Box
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            position:'absolute',
                            top:0,
                            left:{md: 0, lg: 0, xl: -50},
                            height:'100%',
                            cursor:'pointer',
                        }}
                        onClick={previous}
                        >
                        <ArrowCircleLeftIcon sx={{ fontSize: 50 }} />
                        </Box>
                    }
                    {
                        settings.slidesToShow && activeSlide !== 13 &&
                        <Box
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            position:'absolute',
                            top:0,
                            right:{md: 0, lg: 0, xl: -50},
                            height:'100%',
                            cursor:'pointer',
                        }}
                        onClick={next}
                        >
                        <ArrowCircleRightIcon sx={{ fontSize: 50}} />
                        </Box>
                    }
                </>
            </Box>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none', xl: 'none' } }}>
                <>
                    <Slider
                        ref={slider => {
                        sliderRefSmall = slider;
                        }}
                        {...settingsSmall}
                    >
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={ekg}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        EKG
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={radiologi}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        RADIOLOGI
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={usg}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        USG
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={audiometri}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        AUDIOMETRI
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={spirometri}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        SPIROMETRI
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={darah}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        DARAH
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={laboraturium}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        LABORATURIUM
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={visus}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        VISUS
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={konsultasi_dokter_umum_dan_spesialis}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        KONSULTASI DOKTER UMUM DAN SPESIALIS
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={perawatan_ibu}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        PERAWATAN IBU
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={igd}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        IGD
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={praktek_medis}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        PRAKTEK MEDIS
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={vaksinasi}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        VAKSINASI
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={pap_smear}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        PAP SMEAR
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={tht}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        THT
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box
                            px={2}
                        >
                            <Card className='card-service' sx={{height:'100%'}}>
                                <CardMedia
                                    component="img"
                                    height="100%"
                                    image={perawatan_rumah}
                                    alt="green iguana"
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" textAlign={"left"}>
                                        PERAWATAN RUMAH
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Box>
                    </Slider>
                    {
                        activeSlide !== 0 &&
                        <Box
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            position:'absolute',
                            top:0,
                            left:-0,
                            height:'100%',
                            cursor:'pointer',
                        }}
                        onClick={previousSmall}
                        >
                        <ArrowCircleLeftIcon sx={{ fontSize: 50 }} />
                        </Box>
                    }
                    {
                        settingsSmall.slidesToShow && activeSlide !== 15 &&
                        <Box
                        sx={{
                            display:'flex',
                            alignItems:'center',
                            position:'absolute',
                            top:0,
                            right:-0,
                            height:'100%',
                            cursor:'pointer',
                        }}
                        onClick={nextSmall}
                        >
                        <ArrowCircleRightIcon sx={{ fontSize: 50 }} />
                        </Box>
                    }
                </>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default SliderExperimental;
